<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <el-button v-if="userPermissions.indexOf('promotion_create') != -1" type="primary" size="medium"
                   style="margin-bottom: 12px" @click="onCreateClick">添加活动
        </el-button>
        <el-form :inline="true" :model="formData" class="demo-form-inline">
          <el-form-item label="活动名称">
            <el-input placeholder="请输入活动名称" clearable v-model="formData.name"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="onResetSearch">重置</el-button>
          </el-form-item>
        </el-form>

        <ape-table ref="apeTable" :data="promotionList" :columns="columns" :loading="loadingStatus"
                   :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>

          <el-table-column
              v-if="buttonType=='icon'"
              label="操作"
              width="200"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('promotion_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="onEditClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="查看" placement="top-start"
                            v-if="userPermissions.indexOf('promotion_view') != -1">
                  <el-button size="mini" icon="el-icon-view" @click="onViewClick(scope.row.id)"></el-button>
                </el-tooltip>
                  <el-tooltip effect="dark" content="删除" placement="top-start"
                              v-if="userPermissions.indexOf('promotion_delete') != -1">
                  <el-button size="mini" type="danger" icon="el-icon-delete"
                             @click="onDeleteClick(scope.row.id)"></el-button>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>

          <el-table-column
              v-if="buttonType=='text'"
              label="操作"
              width="200"
          >
            <template slot-scope="scope">
              <div>
                <el-button size="mini"
                           v-if="userPermissions.indexOf('promotion_edit') != -1"
                           @click="onEditClick(scope.row.id)">编辑
                </el-button>

                <el-button size="mini"
                           v-if="userPermissions.indexOf('promotion_view') != -1"
                           @click="onEditClick(scope.row.id)">查看
                </el-button>

                <el-button size="mini"
                           type="danger"
                           v-if="userPermissions.indexOf('promotion_delete') != -1"
                           @click="onDeleteClick(scope.row.id)">删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </ape-table>
      </div>
    </PageHeaderLayout>

    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-col class="content-left">
          <el-form ref="promotionFormRef" :model="promotionForm" :rules="rules" label-width="120px">
            <el-form-item label="活动名称" prop="name">
              <el-input v-model="promotionForm.name" :disabled="isView" :maxlenght="30"></el-input>
            </el-form-item>
            <el-form-item label="活动时间" prop="dateValue">
              <el-date-picker
                  v-model="promotionForm.dateValue"
                  @change="onChange"
                  type="datetimerange"
                  align="left"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :disabled="isView">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="赠送会员时长" prop="month">
              <el-input v-model="promotionForm.month"  onkeyup="value=value.replace(/\D/g,'')" :disabled="isView"
                        :maxLength="2">
                <template slot="append">月</template>
              </el-input>
            </el-form-item>

            <el-form-item label="状态" prop="status">
              <el-radio-group v-model="promotionForm.status" :disabled="isView">
                <el-radio label="0" border>启用</el-radio>
                <el-radio label="-1" border>禁用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="首页领取封面">
              <ApeUploader :limit="1" @handleUploadSuccess="handleUploadSuccess" ref="imageRef" :allowUpdate="!isView"
                           @handleUploadRemove="handleUploadRemove"
                           :upload-file-list="uploadFileList"></ApeUploader>
            </el-form-item>
          </el-form>
        </el-col>
      </template>
    </ApeDrawer>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import ApeTable from '@/components/ApeTable';
import ApeDrawer from '@/components/ApeDrawer';
import ApeUploader from '@/components/ApeUploader'

import {mapGetters} from 'vuex';

export default {
  name: "promotion-list",
  components: {PageHeaderLayout, ApeTable, ApeDrawer, ApeUploader},
  data() {
    return {
      isShowMemberListDialog: false,
      modify: false,
      isView: false,
      loadingStatus: false,
      // 表格列表数据
      promotionList: [],
      columns: [
        {
          title: '活动名称',
          value: 'name',
        },
        {
          title: '赠送会员时长(月)',
          value: 'month',
        },
        {
          title: '开始时间',
          value: 'start_at',
          width: '180'
        },
        {
          title: '结束时间',
          value: "end_at",
          width: '180'
        },
        {
          title: "创建时间",
          value: 'created_at',
        },
      ],
      formData: {},
      searchForm: [
        {
          title: "测试",
          value: "name",
        }
      ],
      searchParams: {},

      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },

      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        // offset: 0, // 分页的offset,序号列使用
      },
      promotionForm: {},
      // 表单验证
      rules: {},
      promotionId: '',
      curUnitName: '',
      // 已上传文件列表
      uploadFileList: [],
      dateValue: []
    }
  },

  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'curChooseBankId', 'questionBankInfo', 'curChooseBankName'])
  },

  mounted() {
    this.initPromotionList();
  },

  watch: {
    isView: {
      handler(val) {
        if (val) {
          this.rules = {}
        } else {
          this.rules = {
            name: [
              {required: true, message: '请输入活动名称', trigger: 'blur'}
            ],
            month: [
              {required: true, message: '请输入赠送会员时长', trigger: 'blur'},
            ],
            status: [
              {required: true, message: '请选择活动状态', trigger: 'change'},
            ],
            dateValue: [
              {required: true, message: '请选择活动时间', trigger: 'change'},
            ]
          }
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    //搜索
    onSearchClick() {
      this.initPromotionList(DEL, this.formData);
    },

    //重置搜索
    onResetSearch() {
      this.formData = {}
      this.initPromotionList();
    },

    async initPromotionList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        inputData[param] = params[param];
      }
      let {list, pages} = await this.$api.getPromotionList(inputData);
      this.promotionList = [];
      this.$nextTick(() => {
        this.promotionList = list
      });
      this.pagingData.total = pages.total;
      // this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    // 切换页码操作
    async switchPaging() {
      this.initPromotionList(NO_DEL)
    },

    // 添加
    onCreateClick() {
      this.$refs['promotionFormRef'].resetFields();
      this.uploadFileList = []
      this.isView = false
      this.promotionForm = {}
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '新增活动';

      let status = "0"
      let month = 1
      this.$set(this.promotionForm, "status", status)
      this.$set(this.promotionForm, "month", month)


      this.$nextTick(() => {
        this.drawerData.loading = false;
      })
    },

    // 编辑
    async onEditClick(id) {
      this.isView = false
      if (this.promotionForm.id) {
        delete this.promotionForm.id
      }
      if (!id) {
        return
      }

      this.promotionForm.id = id
      this.promotionId = id
      console.log(this.promotionForm)
      this.modify = true

      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '编辑活动';
      let {info} = await this.$api.getPromotionInfo(id);
      let data = info
      this.setPromotion(data, id)

      this.$nextTick(() => {
        this.drawerData.loading = false;
      })
    },

    // 查看
    async onViewClick(id) {
      if (!id) {
        return
      }

      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '活动详情';
      this.isView = true

      let {info} = await this.$api.getPromotionInfo(id);
      let data = info
      this.setPromotion(data, id)



      this.$nextTick(() => {
        this.drawerData.loading = false;
      })
    },

    // 删除
    onDeleteClick(id) {
      this.$confirm('是否删除该活动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deletePromotionInfo(id);
        if (info == 'ok') {
          this.$nextTick(() => {
            this.initPromotionList(DEL);
          })
        } else {
          this.$message.error(info)
        }
      })
    },

    setPromotion(data, id) {
      if (data.start_at && data.end_at) {
        let dateValue = []
        let startAt = new Date(data.start_at)
        dateValue.push(startAt)
        let endAt = new Date(data.end_at)
        dateValue.push(endAt)
        this.$set(this.promotionForm, "dateValue", dateValue)
      }
      this.$set(this.promotionForm, "name", data.name)
      this.$set(this.promotionForm, "month", data.month)
      this.$set(this.promotionForm, "status", data.status)
      this.$set(this.promotionForm, "id", id)

      this.uploadFileList = [];
      const imageObject = {
        id: data.cover_image_info.id,
        name: data.cover_image_info.title,
        url: data.cover_image_info.full_path,
      };

      this.uploadFileList.push(imageObject);
    },

    // 初始化数据
    resetFormData() {
      this.$nextTick(() => {
        this.$refs['promotionFormRef'].resetFields();
      })
    },

    // 处理抽屉关闭
    drawerClose() {
      this.resetFormData();
      this.drawerData.visible = false;
      this.initPromotionList()
    },

    // 处理抽屉确认
    async drawerConfirm() {
      if (this.isView) {
        this.drawerClose()
        return
      }

      //调用组件的数据验证方法
      this.$refs['promotionFormRef'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },

    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……';
      this.drawerData.loading = true;
      delete this.promotionForm.dateValue
      let month = parseInt(this.promotionForm.month)
      this.$set(this.promotionForm, "month", month)
      let id = await this.$api.storePromotionInfo(this.promotionForm);
      if (id) {
        await this.initPromotionList()
        this.$message.success('保存成功!')
      }
      this.$nextTick(() => {
        this.drawerData.visible = false
      });
      this.resetFormData()
    },

    onMemberClick(row) {
      this.curUnitName = row.name
      this.promotionId = String(row.id)
      this.isShowMemberListDialog = true
    },

    // 图片上传成功回调
    handleUploadSuccess(file, fileList) {
      console.log(file, "file")
      this.promotionForm.cover_image = file.id;
      this.uploadFileList = fileList
    },

    // 图片删除回调
    handleUploadRemove(file, fileList) {
      this.promotionForm.cover_image = 0;
      this.uploadFileList = fileList
    },

    onChange(e) {
      if (this.promotionForm.dateValue.length > 0) {
        this.promotionForm.start_at = this.promotionForm.dateValue[0].getTime()
        this.promotionForm.end_at = this.promotionForm.dateValue[1].getTime()
      }
    },

  }
}
</script>

<style scoped lang="stylus">
.x-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #eeeeee;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>