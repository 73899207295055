<template>
    <div>
        <router-view v-show="$route.matched.length==3"></router-view>
        <PageHeaderLayout v-show="$route.matched.length==2">
            <div class="main-page-content">
                <el-row class="table-header" type="flex" justify="space-between">
                    <el-col :span="18">
                        <el-col>
                            <el-button type="primary" size="medium"
                                       v-if="userPermissions.indexOf('question_type_create') != -1 "
                                       @click="addButton()">添加
                            </el-button>
                        </el-col>
                    </el-col>
<!--                    <el-col :span="5" :offset="1">-->
<!--                        <change-question-bank @confirm="onChangeQuestionBankClick"></change-question-bank>-->
<!--                    </el-col>-->
                </el-row>

                <el-row>
                    <el-col :sm="24" :md="14" :lg="6" align="center">
                        <el-col :sm="5" :md="2" :lg="5" style="margin-top: 12px">名称:</el-col>
                        <el-col :sm="19" :md="19" :lg="18">
                            <el-input v-model="searchForm.name" clearable placeholder="请输入名称"></el-input>
                        </el-col>
                    </el-col>
                    <el-col :sm="24" :md="14" :lg="5" align="center">
                        <el-col :sm="5" :md="2" :lg="5" style="margin-top: 12px">题型:</el-col>
                        <el-col :sm="19" :md="10" :lg="15">
                            <el-select v-model="searchForm.type"
                                       filterable
                                       clearable
                                       placeholder="请选择题型"
                            >
                                <el-option v-for="item in typeList"
                                           :key="item.id"
                                           :label="item.text"
                                           :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </el-col>
                    <el-col :sm="24" :md="10" :lg="4" style="text-align: right">
                        <el-button type="primary" @click="onSearchClick">搜索</el-button>
                        <el-button @click="onResetSearchClick">重置</el-button>
                    </el-col>
                </el-row>

                <ApeTable ref="apeTable" :data="questionBankList" :columns="columns" :loading="loadingStatus"
                          :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
                    <el-table-column
                            slot="first-column"
                            width="80"
                            align="center"
                            label="Drag">
                        <template slot-scope="scope">
                            <el-tooltip effect="dark" content="拖动排序" placement="top-start">
                                <span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="buttonType=='icon'"
                            label="操作"
                            width="150px"
                            fixed="right"
                    >
                        <template slot-scope="scope">
              <span>
                     <el-tooltip effect="dark" content="选题" placement="top-start"
                                 v-if="userPermissions.indexOf('question_type_add') != -1">
                  <el-button size="mini" type="primary" icon="el-icon-plus"
                             @click="addQuestionButton(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('question_type_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="删除" placement="top-start">
                  <span>
                    <el-popover
                            v-if="userPermissions.indexOf('question_type_delete') != -1"
                            placement="top"
                            width="150"
                            v-model="scope.row.visible">
                      <p>确定要删除记录吗？</p>
                      <div style="text-align: right; margin: 0;">
                        <el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>
                        <el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>
                      </div>
                      <el-button slot="reference" type="danger" size="mini" icon="el-icon-delete"></el-button>
                    </el-popover>
                  </span>
                </el-tooltip>
              </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-if="buttonType=='text'"
                            label="操作"
                            width="180px"
                            fixed="right"
                    >
                        <template slot-scope="scope">
                            <div>

                                <el-button size="mini" type="primary"
                                           v-if="userPermissions.indexOf('question_type_add') != -1"
                                           @click="addQuestionButton(scope.row.id)">选题
                                </el-button>
                                <el-button size="mini" v-if="userPermissions.indexOf('question_type_edit') != -1"
                                           @click="editButton(scope.row.id)">编辑
                                </el-button>
                                <el-popover
                                        v-if="userPermissions.indexOf('question_type_delete') != -1"
                                        placement="top"
                                        width="150"
                                        v-model="scope.row.visible">
                                    <p>确定要删除记录吗？</p>
                                    <div style="text-align: right; margin: 0;">
                                        <el-button type="text" size="mini" @click="scope.row.visible=false">取消
                                        </el-button>
                                        <el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定
                                        </el-button>
                                    </div>
                                    <el-button slot="reference" type="danger" size="mini">删除</el-button>
                                </el-popover>
                            </div>
                        </template>
                    </el-table-column>
                </ApeTable>
            </div>
        </PageHeaderLayout>
        <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
            <template slot="ape-drawer">
                <el-col :span="18" class="content-left">
                    <el-form ref="questionTypeForm" :model="formData" :rules="rules" label-width="80px">
                        <el-form-item label="当前题库">
                            <el-input v-model="curChooseBankName" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="formData.name" placeholder="请输入名称"></el-input>
                        </el-form-item>
                        <el-form-item label="难度" prop="difficulty">
                            <el-select
                                    v-model="formData.difficulty"
                                    filterable
                                    clearable
                                    placeholder="请选择难度"
                            >
                                <el-option
                                        v-for="item in difficultList"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="状态" prop="status">
                            <el-radio-group v-model="formData.status">
                                <el-radio label="0" border>启用</el-radio>
                                <el-radio label="-1" border>禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="类型">
                            <div class="el-col-20">
                                <el-select v-model="formData.type"
                                           filterable
                                           clearable
                                           placeholder="请选择题型"
                                >
                                    <el-option
                                            v-for="item in typeList"
                                            :key="item.id"
                                            :label="item.text"
                                            :value="item.id"
                                    ></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                    </el-form>
                </el-col>
            </template>
        </ApeDrawer>
    </div>
</template>

<script>

    const DEL = "del";
    const WINDOW_LOCAL_ORIGIN=process.env.VUE_APP_POROXY_TARGET;
    import PageHeaderLayout from '@/layouts/PageHeaderLayout';
    import ApeTable from '@/components/ApeTable';
    import ApeDrawer from '@/components/ApeDrawer'
    import GetQuestionBankMixins from '../mixins/GetQuestionBankMixins'
    import {mapGetters} from 'vuex';
    import Sortable from 'sortablejs'

    export default {
        components: {
            PageHeaderLayout,
            ApeTable,
            ApeDrawer
        },
        mixins: [GetQuestionBankMixins],
        data() {
            return {
                loadingStatus: false,
                columns: [
                    {
                        title: '名称',
                        value: 'name',
                    },
                    {
                        title: '题目类型',
                        value: 'type_alias'
                    },
                    {
                        title: '难度',
                        value: "difficulty_alias"
                    },
                    {
                        title: '总分',
                        value: "score"
                    },
                    {
                        title: '题目数量',
                        value: "question_count"
                    },
                    {
                        title: '创建时间',
                        value: 'created_at'
                    },
                ],
                // 表格列表数据
                questionBankList: [],
                // 分页信息
                pagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },
                // 抽屉数据
                drawerData: {
                    visible: false,
                    loading: true,
                    loading_text: '玩命加载中……',
                    title: '',
                    width_height: '560px',
                },
                //抽屉表单
                formData: {
                    name:"",
                    status: '0',
                    difficulty:1,
                },
                // 表单验证
                rules: {
                    name: [
                        {required: true, message: '输入标题', trigger: 'blur'},
                    ],
                    difficulty: [
                        {required: true, message: '请输入难度', trigger: 'blur'},
                    ],
                    status: [
                        {required: true, message: '请选择题型状态', trigger: 'blur'}
                    ]
                },
                //题型列表
                typeList: [],

                //难度
                difficultList: [
                    {
                        title: "容易",
                        value: 1
                    },
                    {
                        title: "困难",
                        value: 3
                    }
                ],

                searchForm: []
            }
        },
        computed: {
            ...mapGetters(['userPermissions', 'buttonType','questionBankInfo','curChooseBankName','curChooseBankId'])
        },
        methods: {
            // 切换页码操作
            async switchPaging() {
                this.initQuestionTypeList()
            },
            // 响应添加按钮
            async addButton() {
                this.drawerData.loading_text = '玩命加载中……';
                this.drawerData.visible = true;
                this.drawerData.title = '添加题型';
                this.$nextTick(() => {
                    this.drawerData.loading = false;
                    this.formData.qb_id = this.curChooseBankId;
                });
            },
            // 响应编辑按钮
            async editButton(id) {
                this.drawerData.loading_text = '玩命加载中……';
                this.drawerData.visible = true;
                this.drawerData.title = '修改题型';
                let {info} = await this.$api.getQuestionTypeInfo({id});
                this.$nextTick(() => {
                    this.drawerData.loading = false;
                    this.formData = info;
                    this.formData.difficulty = Math.ceil(info.difficulty);
                });

            },

            //响应题型添加
            addQuestionButton(id) {
                let qbId = this.curChooseBankId;
                this.$router.push(this.$route.path + '/' + id + '/' + qbId+ '/create');
            },

            // 处理抽屉关闭
            drawerClose() {
                this.resetFormData();
                this.drawerData.visible = false;
                this.drawerData.loading = true
            },

            // 处理抽屉确认
            async drawerConfirm() {
                // 调用组件的数据验证方法
                this.$refs['questionTypeForm'].validate((valid) => {
                    if (valid) {
                        this.formSubmit()
                    } else {
                        this.$message.error('数据验证失败，请检查必填项数据！')
                    }
                })
            },

            async getQuestionTypeList(){
                let data = await this.$api.commonDict('question_category');
                this.typeList = data;
            },

            // form数据提交，请求接口
            async formSubmit() {
                this.drawerData.loading_text = '玩命提交中……';
                this.drawerData.loading = true;
                // 调用接口
                let id = await this.$api.storeQuestionTypeList(this.formData);
                this.$nextTick(() => {
                    this.drawerData.visible = false
                });
                this.$nextTick(() => {
                    if (id) {
                        this.initQuestionTypeList()
                    }
                });
                this.$nextTick(() => {
                    this.$message.success('保存成功!')
                });
                this.resetFormData()
            },
            // 相应删除按钮
            async deleteButton(id) {
                let info = await this.$api.deleteQuestionType({id});
                if (info == 'ok') {
                    this.$nextTick(() => {
                        this.initQuestionTypeList('del');
                    })
                } else {
                    this.$message.error(info)
                }
            },
            // 初始化数据
            resetFormData() {
                // 初始化form表单
                this.formData = {
                    name:"",
                    status: '0',
                    difficulty:1,
                };
                this.$nextTick(() => {
                    this.$refs['questionTypeForm'].resetFields();
                })
            },

            async initQuestionTypeList(type) {
                this.loadingStatus = true;
                let inputData = this.$refs['apeTable'].getPagingInfo(type);

                for (let param in this.searchForm) {
                    inputData[param] = this.searchForm[param];
                }


                let qbId = this.curChooseBankId;
                if(!qbId){
                    let userInfo = JSON.parse(localStorage.getItem('user_info'));
                    let name = WINDOW_LOCAL_ORIGIN + '_' + userInfo.id +'_'+'qb_info';
                    let qbInfo = JSON.parse(localStorage.getItem(name));
                    inputData.qb_id = qbInfo.id;
                }else {
                    inputData.qb_id = qbId;
                }


                let {list, pages} = await this.$api.getQuestionTypeList(inputData);
                this.questionBankList = [];
                this.$nextTick(() => {
                    this.questionBankList = list
                });
                this.pagingData.total = pages.total;
                this.pagingData.offset = pages.offset;
                this.loadingStatus = false
            },
            // 拖拽排序数据提交，请求接口
            async dragSortSubmit(orderIds) {
                let data = {
                    ids: orderIds
                };
                let info = await this.$api.orderQuestionTypeList(data);
                if (info == 'ok') {
                    this.$nextTick(() => {
                        this.initQuestionTypeList()
                    })
                }
                this.$message.success('保存成功!')
            },

            // 表格拖拽排序，同层级移动有效果
            dragSort() {
                const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
                this.sortable = Sortable.create(el, {
                    handle: ".drag-handle",
                    setData: function (dataTransfer) {
                        dataTransfer.setData('Text', '')
                    },
                    onEnd: () => {
                        let Ids = [];
                        let tmp = el.querySelectorAll('.drag-handle');
                        for (let i = 0, len = tmp.length; i < len; i++) {
                            Ids.push(tmp[i].dataset.id)
                        }
                        this.dragSortSubmit(Ids)
                    },
                })
            },

            //搜索
            onSearchClick() {
                this.$refs['apeTable'].resetCurPageSize();
                this.initQuestionTypeList(DEL);
            },

            //重置
            onResetSearchClick() {
                this.$refs['apeTable'].resetCurPageSize();
                this.searchForm = {};
                this.initQuestionTypeList();
            },

            onChangeQuestionBankClick(){
                this.initQuestionTypeList(DEL);
                this.getQuestionTypeList();
            }
        },
        mounted() {
            this.initQuestionTypeList();
            this.getQuestionTypeList();
            this.$nextTick(() => {
                this.dragSort()
            })
        },

        watch: {
            "$route.matched": function (n, o) {
                if (n.length === 2) {
                    this.initQuestionTypeList();
                }
            },

          'curChooseBankId'(newVal) {
            if (newVal) {
              this.initQuestionTypeList(DEL);
            }
          }
        },
    }
</script>

<style lang="stylus" scoped>
    .table-header
        margin-bottom 12px

    .drag-handle
        font-size 24px
        cursor pointer

    .el-input-group__prepend, .el-input-group__append
        background #ffffff
        padding 0 12px

    .el-input-group__append
        color #ffffff
        background #1890ff

    .el-popover .el-checkbox-group .el-checkbox
        margin-left 0
        margin-right 12px

    .el-radio.is-bordered
        width 100px
    .el-select > .el-input
        width 200px

    .el-color-picker
        position absolute
</style>